import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

const appointmentsBaseUrl = "appointments";

class FlatfileService {
  getFlatfileToken = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token/`);
  };

  getFlatfileTokenMx = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  };

  getMassiveUploadTokens = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/massive-upload-tokens/`);
  };

  downloadFlatfileTemplate = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_template/`, { responseType: "blob" });
  };
}

const flatfileService = new FlatfileService();

export default flatfileService;
