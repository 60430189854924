export const taskStatus: Record<string, string> = {
  error: "Error",
  waiting: "No inicializada",
  running: "En ejecución",
  finished: "Terminada correctamente",
};

// If more tasks are added in the future, add them here :catsmile:
// https://www.youtube.com/watch?v=dQw4w9WgXcQ
export const taskName: Record<string, string> = {
  massive_upload_items: "Carga masiva de Items",
  massive_price_update: "Actualización de precios",
  async_create_offliner: "Offliner masivo",
  massive_upload_labmeasures: "Carga masiva de LabMeasures",
  massive_upload_labpanels: "Carga masiva de LabPanels",
};
