import React, { useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Loadable from "react-loadable";
import Swal from "sweetalert2";

import asyncTaskService from "~/utils/api/v1/asyncTaskService";
import flatfileService from "~/utils/api/v1/flatfileService";
import { taskName, taskStatus } from "~/utils/constants/asyncTasks";
import { asyncTask } from "~/utils/interfaces/AsyncTasks";

moment.locale("es");

const LoadingComponent = (props: any) => {
  return <div>Loading...</div>;
};

const ItemLoadableComponent = Loadable({
  loader: async () => import("../components/massiveUpload/ItemUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

const LabMeasureLoadableComponent = Loadable({
  loader: async () => import("../components/massiveUpload/LabMeasureUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

const LabPanelLoadableComponent = Loadable({
  loader: async () => import("../components/massiveUpload/LabPanelUpload"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

const LabItemPricesUpdateComponent = Loadable({
  loader: async () => import("../components/massiveUpload/LabItemPricesUpdate"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

function MassiveUpload() {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [flatfileTokens, setFlatfileTokens] = useState<Record<string, string>>({
    measures: "",
    panels: "",
    medicalservices: "",
    "lab-measures": "",
    "lab-panels": "",
  });
  const [asyncTasks, setAsyncTasks] = useState<asyncTask[]>([]);

  const fetchToken = async () => {
    try {
      const req = await flatfileService.getMassiveUploadTokens();
      setFlatfileTokens(req.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchToken();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: `Ha ocurrido un error: ${error}`,
      });
    }
  }, [error]);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const res = await asyncTaskService.getAsyncTasks();
      setAsyncTasks(res.data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex items-center">
        <h1 className="mb-3">Carga Masiva</h1>
        <a
          target="_blank"
          href="https://examedi.notion.site/Carga-Masiva-Betty-eff428adb7dc4c30a36e1e57eb90d730"
          className="pl-6 no-underline"
          rel="noreferrer"
        >
          ¿Cómo funciona?
        </a>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["measures"]}
          type="measures"
        />
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["panels"]}
          type="panels"
        />
        <ItemLoadableComponent
          flatfileToken={flatfileTokens["medicalservices"]}
          type="medicalservices"
        />
        <LabMeasureLoadableComponent flatfileToken={flatfileTokens["lab-measures"]} />
        <LabPanelLoadableComponent flatfileToken={flatfileTokens["lab-panels"]} />
        <LabItemPricesUpdateComponent flatfileToken={flatfileTokens["price-update"]} />
      </div>
      <div>
        <h1 className="my-3">Mis Cargas</h1>
        {loading && <CircularProgress />}
        {!loading && (
          <div>
            {asyncTasks.map((task: asyncTask, index: number) => {
              return (
                <div
                  key={`task_${index}`}
                  className="pb-3"
                >
                  <h3>{taskName[task.function_name]}</h3>
                  <p className="pl-2">ID: {task.id}</p>
                  <p className="pl-2">Fecha de creación: {moment(task.created_at).format("LLL")}</p>
                  <div className="flex flex-row pl-2">
                    <p className="pr-2">Estado:</p>
                    <p style={task.status === "error" ? { color: "red" } : {}}>{taskStatus[task.status]}</p>
                  </div>
                  {task.status === "error" && (
                    <Accordion className="mt-2">
                      <AccordionSummary>Mostrar error</AccordionSummary>
                      <AccordionDetails>{task.output}</AccordionDetails>
                    </Accordion>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default MassiveUpload;
