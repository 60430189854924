import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

class AsyncTaskService {
  getAsyncTasks = async (): Promise<AxiosResponse<any>> => {
    return api.get(`v2/tasks/`);
  };
}

const asyncTaskService = new AsyncTaskService();

export default asyncTaskService;
